
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// const Parse = require('parse/node');




const devEnv = true;  

const showOld = false;
































let appId = "";
let appJSKey = "";

if (devEnv === false){
    //we're live
    appId = "1yc4JCgok8uzIi6oq5FpnC0zy71lwa8ulm011qSv";
    appJSKey = "h9DbUo0w4tZrOs0IxHxu2JjibyN3eTHoP50K8uWA";
    // Parse.initialize("1yc4JCgok8uzIi6oq5FpnC0zy71lwa8ulm011qSv", "h9DbUo0w4tZrOs0IxHxu2JjibyN3eTHoP50K8uWA"); // LIVE
} else {

    
    // appId = "UQ4iiKCzkBU7sIxs3rehG8VNNsnnO3DuTF9D06p4"; // backup dev 250101
    // appJSKey = "d7xMMCKWonNuHNiFtlehZzRHMAkpQZT9L22a6Wci"; // backup dev 250101

    // appId = "UFJhqRvtfzulEmVFj68PjdgR1TuDjc8KEhCyG335"; // backup dev 241215
    // appJSKey = "d7xMMCKWonNuHNiFtlehZzRHMAkpQZT9L22a6Wci"; // backup dev 241215

    appId = "Ph2h6toslwxIkk4k3SZPS17SvDAauVqQMVBVc7ws"; // backup dev 241123   PAID
    appJSKey = "atu9YYPRmjlFqgKTxJLXLhovxJDauc26WNh8ntQI"; // backup dev 241123

    // appId = "lftR2W1Do6Hb5CvCqyulxH07O5N0Kbsqvh7Hf7k5"; // backup dev 240928
    // appJSKey = "xqf3FgRlAmYAMY5sK19ykF3KE584MAM2TqqCLd1H"; // backup dev 240928

    // appId = "CFEz2HL1Wel6I9AZF26Dc0TJsRoFbuBLYLPDb2LT"; // backup dev 240817
    // appJSKey = "iUMdYYmo8TwgpBMoa1MHEneYSX43CjfX4YIP4vze"; // backup dev 240817

    // appId = "KujTMKV7XJytfrOmU14xET8VknR7Wzo2A8lJDZsn"; // backup dev 240720
    // appJSKey = "tUeuWkZhBr4S8o9Vc3xlic0akoX9BHpAmxBe8CtH"; // backup dev 240720

    // appId = "u88xU2rxuFPsAN8qUlKRoXA5ZwvMXuOPVkblcR1H"; // backup dev 240713
    // appJSKey = "goNVQcSeeSlvOQiUBnGno7K0MyUPCEx1WLAvL9GI"; // backup dev 240713

    // appId = "qipc1ugzVRaV9r2ddgTzagoXYVi9e1RShbrqKk91"; // backup dev 240601
    // appJSKey = "CkrTn3YsBpIEYzMmZHRdrMg0SuplV3FzrinYJknW"; // backup dev 240601

    // appId = "4KmKgLEtqzfVna3eN2HuxcUEgVjkhoqKeIi0ICBB"; // backup dev 240526 v3
    // appJSKey = "FtEeEL45HFQAqPI3YZ5325aayHkFkldHxUTnmoHu"; // backup dev 240526 v3

    // appId = "MtNAdCuTD5Y1im1rXkGVOpQ8hUK89vhJHAmsv1Wi"; // backup dev 240407 2
    // appJSKey = "aTT9sY3BOTfPcrVP89SR7te20gXBgVD7sPmrfcCc"; // backup dev 240407 2

    // appId = "zBPtUJTqVXNWCtbZr3lveZrHNvJRcxQRlGZ7nkFZ"; // backup dev 240203 2
    // appJSKey = "h8aqioudCoFguaXtM9i8HZ1bGXoSMz8Y0dUVzyyV"; // backup dev 240203 2

    // appId = "M2VDRGLEd2uZqpCm2tfAGmXNuBIds0Bc0sVOzrWh"; // backup dev 240103
    // appJSKey = "27uBzseemngJME4Rv0xJxSxzXWhiJNrK9WvHg5OA"; // backup dev 240103

    // appId = "jgay2FPOPyRGBMoX7raLk3FBdlZSB9fV9Fb6AneP"; // backup dev clone of 151123
    // appJSKey = "msbRc7KtIHJCMAwgEMAzGpebMqDcqZ2zhUnTJIdw"; // backup dev clone of 151123

    // appId = "ZfkoxEeFNozUweLcSKZIkyZIUZToAtRM8ugP06cM"; // backup dev 151123
    // appJSKey = "CXVZFcKkRP1VN8q3Oevrf9QRiGSOaMuBxLFQ0llp"; // backup dev 151123

    // appId = "cEGwAmoe9vuUrWrLUSuuFp7iEWRWTaFDq6JqCia6"; // backup dev 071123
    // appJSKey = "1TOYKzGd3ULqfjjOHBLvTuJoAEkwRtRQq3uaSsuY"; // backup dev 071123

    // appId = "NIr35reptoE5FkD8CZX1nfdhk9WDdaa93Eyo0l4I"; // backup dev 291023 2
    // appJSKey = "r1hmFn0wdKd5MtBMFYbxc5LG0MNAzY0n3v6hFyq8"; // backup dev 291023 2

    // appId = "JHuuMbxR9vXl9HhyhzVRNgmIoyAe7IuCOvS7oU99"; // backup dev 011023
    // appJSKey = "xZ3dTxr2NLPOn5sA20cU0rJgMwZjzUPqpBTvXsh2"; // backup dev 011023

    // appId = "i0yVa8aAkFgPZowrJvo2Xz5ZR0W0ewLE28eACKR9"; // backup dev 300923 4
    // appJSKey = "jNS1GXlVjYvsoH2oS7JZf8yT2eFhoZKmq7nHV2mF"; // backup dev 300923 4

    // appId = "mp6yZr9sbkhJA7aOc3A8WOJYby6aI0JWgAM1jnKo"; // backup dev 260923 
    // appJSKey = "7YHLvk3XpjuaPNCKzxdMSU3AuKYSLdOPBA9qw1rM"; // backup dev 260923 

    // appId = "PXTVGT1yoMAgMLWzXHFkthfRvdDlck7RPXRMIpno"; // backup dev 230923 
    // appJSKey = "p4jdqfYzGVyAqRJTkyAG1X4u1GugJWTpvMawekg8"; // backup dev 230923 

    // appId = "nDzbNvNhJmWGyrhvO1DVWpTD5llHCt7Aoz4uRLVa"; // backup dev 170923 
    // appJSKey = "OXuHeruFFLCC9mjs3U99mrXDrqgKh0T0u1LuUVxx"; // backup dev 170923 

    // appId = "LkxZagvft0MwJPuwfcEblHp8LrvJquU17BuNeMT8"; // backup dev 160923 
    // appJSKey = "or460HMuWRRBRTRQ4f44jJxDGjrzGLYvVzYhviuA"; // backup dev 160923 
    

    // appId = "R7xTwd9ypikwbKgvsKrwoWwbt6gEblbtQ296MxZI"; // backup dev 140923 late
    // appJSKey = "WP7pkhmU2Ov4Y8CrAB1ULqKHJnJ2sGjYmpQ9iFf2"; // backup dev 140923 late


}
// Parse.initialize("PwnDbYEP3bvUUWDdhaIexqBwvcZso3SQaLCObaP3", "y1wllzXqWpCipZb8Dw90JIQAiJxkdhpCPNCOHcDz"); // cant remember

//javascriptKey is required only if you have it on server.


// PAGESPEED Parse.serverURL = 'https://parseapi.back4app.com/'


// const app = createApp(App);

// app.use(Parse)
// app.use(router)

// app.mount('#app')

// Vue.prototype.$globalPushForwardInterval = 4000;

// 1. Assign app to a variable
let app = createApp(App)

// 2. Assign the global variable before mounting
app.config.globalProperties.$isLoading = false; // not currently used, but seems to be a nicer, more obvious way to load a global variable than the inject method - couldnt find anything to say it was a worse method 

app.provide('globalPushForwardInterval', 120000).provide('showOld', showOld).provide('devEnv', devEnv).provide('appId', appId).provide('appJSKey', appJSKey).use(router).mount('#app') // actually the old version above also works fine...

// global variable


